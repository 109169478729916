import React, { useEffect, Fragment } from 'react';
import { cookiesHubID } from 'lib/helpers';
import { getTimezone } from 'imports/checkout/api/utils';

const CookiesHub = ({ host, isProd }) => {
  if (!cookiesHubID?.[host] || cookiesHubID?.[host]?.trim() === '') return null;
  const url = isProd ? 'https://cdn.cookiehub.eu/c2/' : 'https://dash.cookiehub.com/dev/';
  return (
    <Fragment>
      <script src={`${url}${cookiesHubID?.[host]}.js`}></script>
    </Fragment>
  );
};

function isTimezoneInRegion(timezone) {
  const timezonesToCheck = [
    //UK
    'Europe/London',
    //EU
    'Europe/Vienna',
    'Europe/Brussels',
    'Europe/Sofia',
    'Europe/Zagreb',
    'Asia/Nicosia',
    'Europe/Prague',
    'Europe/Copenhagen',
    'Europe/Tallinn',
    'Europe/Helsinki',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Busingen',
    'Europe/Athens',
    'Europe/Budapest',
    'Europe/Dublin',
    'Europe/Rome',
    'Europe/Riga',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Malta',
    'Europe/Amsterdam',
    'Europe/Warsaw',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Europe/Bucharest',
    'Europe/Bratislava',
    'Europe/Ljubljana',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Stockholm',
    //california
    'America/Los_Angeles',
  ];

  if (timezonesToCheck.includes(timezone)) {
    return true;
  } else {
    return false;
  }
}

export const CookiesHubScript = () => {
  const timezone = getTimezone();
  useEffect(() => {
    const startScript = () => {
      if (!window.cookiehub || !isTimezoneInRegion(timezone)) return;
      var cpm = {};
      window.cookiehub.load(cpm);
    };
    startScript();
  }, []);
  return null;
};

export default CookiesHub;
